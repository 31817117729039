import {aliRequestIns, userRequest} from "utils/request";
import { API_ALI_URI, API_SIT_URL } from "config";
import { IAliResponse, IMe, ILogin } from "models";

export async function getCompanies(commonToken: string): Promise<any> {
    return userRequest({
        method: "get",
        url: `${API_SIT_URL}/oauth/getUserInfoByCommonToken`,
        params: {
            commonToken,
        },
    });
}

export async function createIotToken(authCode: string, companyId: string): Promise<
    IAliResponse<{
        identityId: string,
        iotToken: string,
        iotTokenExpire: number,
        refreshToken: string,
        refreshTokenExpire: number,
    }>
> {
    const response = (await aliRequestIns.post(
        `${API_ALI_URI}/link/oa/token/create`,
        {},
        {
            params: {
                authCode,
                companyId,
            },
        },
    )).data;

    return response;
}

export async function getMe(iotToken: string): Promise<IAliResponse<IMe>> {
    const response = (await aliRequestIns.post(
        `${API_ALI_URI}/link/oa/users/identity`,
        {},
        {
            params: {
                iotToken,
            },
        },
    )).data;

    return response;
}

export async function logout() {
    const response = (await userRequest.get(
        `${API_SIT_URL}/park/logout`,
        {
            headers: {
                Authorization: JSON.parse(window.localStorage.getItem("user") as string).accessToken,
            },
        },
    )).data;

    return response;
}

// export async function createOAuthToken(iotToken: string): Promise<{
//     access_token: string,
//     expires_in: number,
//     jti: string,
//     scope: string,
//     token_type: string,
// }> {
//     const response = (await requestIns.post(
//         `${API_UAA_URI}/oauth/token`,
//         `grant_type=aliiot_token&aliiot_token=${iotToken}`,
//         {
//             headers: {
//                 "Content-Type": "application/x-www-form-urlencoded",
//                 "Authorization": "Basic " + Buffer.from(`${CLIENT_ID}:${CLIENT_SECRET}`).toString("base64"),
//             },
//         },
//     )).data;

//     return response;
// }

export async function employeeCreate(iotToken: string) {
    const response = (await aliRequestIns.post(
        `${API_ALI_URI}/link/oa/employee/create`,
        {},
        {
            params: {
                authCode: iotToken,
                codeType: "AUTH_CODE",
            },
        },
    )).data;

    return response;
}

// 新：登录
export async function login(data: ILogin) {
    return userRequest({
        method: "post",
        url: `${API_SIT_URL}/park/login`,
        params: {
            ...data,
            grant_type: "dscloud_password",
            scope: "all",
        },
    });
}

// cookieCreate
export async function cookieCreate() {
    return userRequest({
        method: "post",
        url: `${API_SIT_URL}/parkOauth/cookieCreate`,
        headers: {
            Authorization: JSON.parse(window.localStorage.getItem("user") as string).accessToken,
        },
    });
}

import { Button, Form, Icon, Input, Alert } from "antd";
import React, { Component } from "react";
import api from "api";
import Message from "antd/lib/message";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WrappedFormInternalProps } from "antd/lib/form/Form";
import styles from "./index.module.css";
import { toRSA } from "../../../utils/rsa";

interface IState {
  formData: {
    username: string;
    password: string;
  };
  msg: string;
  isShowMsg: boolean;
  iconLoading: boolean;
}

class LoginForm extends Component<RouteComponentProps & WrappedFormInternalProps> {
  public state: IState = {
    formData: {
      username: "",
      password: "",
    },
    msg: "",
    isShowMsg: false,
    iconLoading: false,
  };
  constructor(props) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.login = this.login.bind(this);
  }
  // 登录获取 getCompanies
  public async login(commonToken: string) {
    try {
      const res = await api.account.getCompanies(commonToken);
      if (res.data.code === "200") {
        this.setState({
          iconLoading: false,
        });
        window.localStorage.setItem("userCommon", JSON.stringify(res.data.data));
        // setCookie
        api.account.cookieCreate();
        this.props.history.push("/");
      } else {
        this.setState({
          isShowMsg: true,
          msg: res.data.msg,
          iconLoading: false,
        });
      }
      // const companies = responseCompanies.data;
      // const companyId = companies[0].companyId;
      //
      // await api.account.createIotToken(commonToken, companyId);

    } catch (error) {
      Message.error("登录失败");
      this.setState({
        iconLoading: false,
      });
    }
  }
  // 登录按钮
  public async handleLogin() {
    if (/\s/.test(this.state.formData.username)) {
      this.setState({
        isShowMsg: true,
        msg: "登录名不能含有空格",
      });
      return;
    }
    if (!this.state.formData.username.trim()) {
      this.setState({
        isShowMsg: true,
        msg: "登录名不能为空",
      });
      return;
    }
    if (!this.state.formData.password.trim()) {
      this.setState({
        isShowMsg: true,
        msg: "密码不能为空",
      });
      return;
    }
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          this.setState({
            isShowMsg: false,
            iconLoading: true,
          });
          const res = await api.account.login({
            username: this.state.formData.username,
            password: encodeURIComponent(toRSA(this.state.formData.password)),
          });
          if (res.data.code === "200") {
            window.localStorage.setItem("user", JSON.stringify(res.data.data));
            this.login(res.data.data.commonToken);
          } else {
            this.setState({
              isShowMsg: true,
              msg: res.data.msg,
              iconLoading: false,
            });
          }
        } catch (e) {
          this.setState({
            iconLoading: false,
          });
        }
      }
    });
  }
  // 输入框事件
  public async handleInput({ value, name }: { value: string, name: string }) {
    const formData = this.state.formData;
    this.setState({
      formData: { ...formData, [name]: value },
      isShowMsg: false,
    }, (): void => {
      this.props.form.validateFields([name], { force: true });
    });
  }

  // 用户名禁止输入空格
  public async validatorUsername(rule, value, callback) {
    const reg = /\s/;
    if (value && reg.test(value)) {
      this.setState({
        isShowMsg: true,
        msg: "用户名不能输入空格",
      });
      callback();
    } else {
      callback();
    }
  }

  public render() {
    const { getFieldDecorator } = this.props.form;
    const { handleLogin, handleInput } = this;
    const { msg, isShowMsg, iconLoading } = this.state;
    return (
      <Form
        className={styles.label}
      >
        {isShowMsg ? (<Alert message={msg} type="error" showIcon closable />) : null}
        <Form.Item label={"登录名"}
          style={{ marginBottom: "0" }}>
          {getFieldDecorator("username", {
            rules: [{
              validator: this.validatorUsername.bind(this),
            }],
          })(<Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="手机号/会员名/邮箱"
            allowClear
            name="username"
            onChange={(e) => handleInput(e.target)}
          />)}
        </Form.Item>
        <Form.Item label={"登录密码"} style={{ marginBottom: "10px" }}>
          {getFieldDecorator("password")(<Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="请输入您的登录密码"
            type="password"
            name="password"
            allowClear
            onPaste={(e) => e.preventDefault()}
            onChange={(e) => handleInput(e.target)}
          />)}
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            style={{
              width: "100%",
              backgroundColor: "#00a2ca",
              fontWeight: 700,
              fontFamily: "Microsoft YaHeiMicrosoft YaHei,Hiragino Sans GB,\\5b8b\\4f53",
            }}
            loading={iconLoading}
            onClick={handleLogin}
          >登录</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "LoginForm" })(withRouter(LoginForm));

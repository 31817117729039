import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface IProps {
    public?: boolean;
}

class Page extends React.Component<IProps & RouteComponentProps> {
    public componentDidMount() {
        const accessToken = JSON.parse(window.localStorage.getItem("user") as string) || "";
        if (!accessToken) {
            this.props.history.push("/login");
        }
    }
    public render() {
        return <>
            { this.props.children }
        </>;
    }
}

export default withRouter(Page);

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import message from "antd/lib/message";
import { ENVIRONMENT } from "config/environment";
import styles from "./index.module.css";
import api from "api";

class Register extends React.Component<RouteComponentProps> {
    public componentDidMount() {
        this.initAliRegisterFrame();
    }
    public initAliRegisterFrame() {
        const miniLoginEmbedder = new (window as any).MiniLoginEmbedder();

        miniLoginEmbedder.addEvent("onMessage", async (args: any) => {
            // 注册成功
            if (args.action && args.action === "register" && args.resultCode === "100") {
                try {
                    await api.account.employeeCreate(args.token);
                    message.success("注册成功");
                    this.props.history.replace("/login");
                } catch (err) {
                    //
                }
            }
        });

        miniLoginEmbedder.init({
            targetId: "alibaba-register-iframe",
            appKey: ENVIRONMENT.oa_app_key,
            sso: "false",
            iframeUrl: "https://openaccount-login.aliyun.com/login/mini_register.htm",
            lang: "zh_CN",
            notLoadSsoView: "",
            notKeepLogin: "true",
            addSwitchUrl: "false",
            displayLoginId: "false",
            iframeHeight: "430px",
            iframeWidth: "780px",
        });
    }
    public render() {
        return <div className={styles.container}>
            <div className={styles.logo}>
                {/* <img src={require("static/images/logo.png")} alt="logo" /> */}
                {/* <span className={styles.logoDivider}></span>
                <span className={styles.logoText}>i友未来社区</span> */}
                <span className={styles.logoText}>未来社区</span>
            </div>
            <div className={styles.body}>
                <div className={styles.form} id="alibaba-register-iframe" />
            </div>
        </div>;
    }
}

export default Register;

import HomePage from "pages/home";
import LoginPage from "pages/login";
import RegisterPage from "pages/register";

const defaultRoutes = [
    { path: "/", component: HomePage },
    { path: "/login", component: LoginPage },
    { path: "/register", component: RegisterPage },
];

export const routes = [
    ...defaultRoutes,
];

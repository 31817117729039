import React from "react";
import ReactDOM from "react-dom";
import Pages from "pages";
import { ENVIRONMENT } from "config/environment";
import "react-app-polyfill/ie9";
import "./styles/index.css";

import "antd/dist/antd.css";

class App extends React.Component<{}, { preLoading: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            preLoading: true,
        };
    }
    public async componentDidMount() {
        await ENVIRONMENT.init();
        this.setState({ preLoading: false });
    }
    public render() {
        if (this.state.preLoading) {
            return null;
        }

        return <Pages />;
    }
}

ReactDOM.render(<App />, document.getElementById("root"));

import JSEncrypt from "jsencrypt";

let publicKey;
if (process.env.NODE_ENV === "production") {
    publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCIlsAepAMnB13fpXhMpmGGi1cUGu+WY1Fx4TWW0QJg/PAlZaKK/Fd7zTBRR0C+HD0pSJFPaZT/LekItiADPOjo097iYI2B2Vblk7LI0cgOyagdouF+nR3I6K4Ln1kUxMFh89SAfnctuF2j2goy0jDyg2EYubeuROLspmZax6FBIwIDAQAB";
} else {
    publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCm64eOFoQqSjOc+uEyHCRmHwRihIm9UzzSsgXEX3CVi3gmIbDOr9yjw1aXbRdcIrmG2IgLLWNsNhuaECv/2Zk5kC+Reyc5ySgCyUMstLtP3EEcoubGK+D4kbkiZGz5cMsPM8h3R9LCdjhifJESmSNbiEVK9gtc/FdwptCr/L/uMwIDAQAB";
}
export function toRSA(password) {
    const encryptor = new JSEncrypt(); // 创建加密对象实例
    encryptor.setPublicKey(publicKey); // 设置公钥
    return encryptor.encrypt(password); // 加密
}

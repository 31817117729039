import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Carousel from "antd/lib/carousel";
import styles from "./index.module.css";
import LoginForm from "./LoginForm";

class Login extends React.Component<RouteComponentProps> {
    public render() {
        return <div className={styles.container}>
            <div className={styles.logo}>
                {/* <img src={require("static/images/logo.png")} alt="logo" /> */}
                {/* <span className={styles.logoDivider}></span>
                <span className={styles.logoText}>i友未来社区</span> */}
                <span className={styles.logoText}>未来社区</span>
            </div>
            <div className={styles.body}>
                <div className={styles.carousel}>
                    <Carousel autoplay={true}>
                        <div className={styles.slide}>
                            <img src={require("static/images/carousel_item_1.png")} alt="carousel" />
                        </div>
                        {/* <div className={styles.slide}>
                            <img src={require("static/images/carousel_item_2.png")} alt="carousel" />
                        </div>
                        <div className={styles.slide}>
                            <img src={require("static/images/carousel_item_3.png")} alt="carousel" />
                        </div> */}
                    </Carousel>
                </div>
                <div className={styles.form}>
                    <LoginForm />
                </div>
            </div>
        </div>;
    }
}

export default Login;

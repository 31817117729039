import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import Icon from "antd/lib/icon";
import { Page } from "components";
import { ENVIRONMENT } from "config/environment";
import { IMe } from "models";
import api from "api";
import styles from "./index.module.css";

interface IState {
    me: Partial<IMe>;
}

class HomePage extends React.Component<RouteComponentProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            me: {},
        };
    }
    public componentDidMount() {
        this.getMe();
    }
    public async getMe() {
        const me = JSON.parse(window.localStorage.getItem("user") as string) || "";

        this.setState({ me });
    }
    public async onLogout() {
        try {
            // const iotToken = getCookie(ENVIRONMENT.cookie_name);
            await api.account.logout();
        } catch (error) {
            //
        } finally {
            this.props.history.replace("/login");
        }
        window.localStorage.clear();
    }
    public render() {
        const { me } = this.state;

        const MENUS = [
            {
                title: "一卡通平台",
                description: "为用户提供制卡发卡的卡务管理、数据交换、财务清分结算等功能的一卡通管理平台",
                cover: require("static/images/yikatong_cover.png"),
                path: ENVIRONMENT.links_ykt,
            },
            {
                title: "园区管理平台",
                description: "致力于帮助科技园区提高业务管理水平的智慧园区管理系统",
                cover: require("static/images/yuanqu_cover.png"),
                path: ENVIRONMENT.portal_url,
            },
            {
                title: "会员营销平台",
                description: "会员营销平台支持运营、商家、会员后台的全面管理，通过会员、商品、订单等功能对线下线上多业务进行统一管理",
                cover: require("static/images/huiyuan_cover.png"),
                path: ENVIRONMENT.links_hyyx,
            },
        ];

        const overlayMenu = (
            <Menu>
                <Menu.Item key="0">
                    <div onClick={() => this.onLogout()}>退出登录</div>
                </Menu.Item>
            </Menu>
        );

        return <Page>
            <div className={styles.container}>
                <div className={styles.logo}>
                    {/* <img src={require("static/images/logo.png")} alt="logo" /> */}
                    {/* <span className={styles.logoDivider}></span>
                    <span className={styles.logoText}>i友未来社区</span> */}
                    <span className={styles.logoText}>未来社区</span>
                </div>
                <div className={styles.user}>
                    <Dropdown
                        overlay={overlayMenu}
                        trigger={["click"]}
                    >
                        <span className={styles.userInfo}>
                            <img src={require("static/images/avatar_default.png")} alt="avatar" />
                            <span className={styles.userName}>{me.nickname || ""}</span>
                            <Icon type="down" />
                        </span>
                    </Dropdown>
                </div>
                <div className={styles.body}>
                    {
                        MENUS.map((menu, idx) => {
                            return <div
                                key={idx}
                                className={styles.menu}
                                onClick={() => {
                                    if (!menu.path) {
                                        return;
                                    }
                                    window.location.href = menu.path;
                                }}
                            >
                                <div className={styles.menuCover}>
                                    <img src={menu.cover} alt="menu cover" />
                                </div>
                                <div className={styles.menuTitle}>{menu.title}</div>
                                <div className={styles.menuDesc}>{menu.description}</div>
                                <img
                                    className={styles.menuCursor}
                                    src={require("static/images/menu_cursor.png")}
                                    alt="menu cursor"
                                />
                            </div>;
                        })
                    }
                </div>
            </div>
        </Page>;
    }
}

export default HomePage;

import axios from "axios";
import api from "api";
import { ENVIRONMENT } from "config/environment";
import { getCookie } from "utils/cookie";

export const requestIns = axios.create();
export const aliRequestIns = axios.create();
export const userRequest = axios.create();

aliRequestIns.interceptors.response.use(async response => {
    const data = response.data;

    if (data.code === 200) {
        return response;
    } else if (data.code === 401) {
        try {
            const iotToken = getCookie(ENVIRONMENT.cookie_name);
            if (iotToken && ((response.config.url || "").indexOf("dscloud-account-center/park/logout") < 0)) {
                await api.account.logout();
            }
        } catch (error) {
            //
        } finally {
            window.location.href = `${ENVIRONMENT.innoecos_url}/login?timestamp=${+new Date()}`;
        }
        return Promise.reject(response);
    } else {
        return Promise.reject(response);
    }
}, error => {
    return Promise.reject(error);
});

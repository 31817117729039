import axios from "axios";
import { API_SIT_URL } from "config";

export const ENVIRONMENT = {
    portal_url: "",
    oa_app_key: "",
    innoecos_url: "",
    cookie_name: "",
    links_ykt: "",
    links_hyyx: "",

    init: async () => {
        const config = (
            await axios.get(`${API_SIT_URL}/parkConfig`)
        ).data as {
            portal_url: string,
            oa_app_key: string,
            innoecos_url: string,
            cookie_name: string,
            links_ykt: string,
            links_hyyx: string,
        };

        ENVIRONMENT.innoecos_url = config.innoecos_url;
        ENVIRONMENT.oa_app_key = config.oa_app_key;
        ENVIRONMENT.portal_url = config.portal_url;
        ENVIRONMENT.cookie_name = config.cookie_name;
        ENVIRONMENT.links_ykt = config.links_ykt;
        ENVIRONMENT.links_hyyx = config.links_hyyx;
    },
};
